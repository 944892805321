define("ember-svg-jar/inlined/lock-battery-full", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.5 9h-12c-.281 0-.5.25-.5.5v5c0 .281.219.5.5.5h12c.25 0 .5-.219.5-.5v-5c0-.25-.25-.5-.5-.5zm-12-2h12C18.875 7 20 8.125 20 9.5v.5c.531 0 1 .469 1 1v2c0 .563-.469 1-1 1v.5c0 1.406-1.125 2.5-2.5 2.5h-12A2.468 2.468 0 013 14.5v-5C3 8.125 4.094 7 5.5 7zM17 10v4H6v-4h11z\" fill=\"#00996F\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});