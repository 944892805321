define("ember-svg-jar/inlined/lock-locked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 6a2.487 2.487 0 00-2.5 2.5V10h5V8.5C14.5 7.125 13.375 6 12 6zM7.5 8.5C7.5 6.031 9.5 4 12 4c2.469 0 4.5 2.031 4.5 4.5V10h.5c1.094 0 2 .906 2 2v6c0 1.125-.906 2-2 2H7c-1.125 0-2-.875-2-2v-6c0-1.094.875-2 2-2h.5V8.5zM13 14c0-.531-.469-1-1-1-.563 0-1 .469-1 1v2c0 .563.438 1 1 1 .531 0 1-.438 1-1v-2z\" fill=\"#00996F\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});