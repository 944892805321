define("ember-svg-jar/inlined/lock-battery-critical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.5 7v2h-4c-.281 0-.5.25-.5.5v5c0 .281.219.5.5.5h3.688A2.707 2.707 0 009 16c0 .375.063.719.188 1H5.5A2.468 2.468 0 013 14.5v-5C3 8.125 4.094 7 5.5 7h4zm8 10h-3.719c.125-.281.219-.625.219-1 0-.344-.094-.688-.219-1H17.5c.25 0 .5-.219.5-.5v-5c0-.25-.25-.5-.5-.5h-4V7h4C18.875 7 20 8.125 20 9.5v.5c.531 0 1 .469 1 1v2c0 .563-.469 1-1 1v.5c0 1.406-1.125 2.5-2.5 2.5zm-6-10a.76.76 0 01.75.75v5.5a.74.74 0 01-.75.75.722.722 0 01-.75-.75v-5.5A.74.74 0 0111.5 7zm-1 9c0-.531.438-1 1-1 .531 0 1 .469 1 1 0 .563-.469 1-1 1-.563 0-1-.438-1-1z\" fill=\"#C00\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});