define("ember-svg-jar/inlined/lock-unlocked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.5 6A2.487 2.487 0 0014 8.5V10h1c1.094 0 2 .906 2 2v6c0 1.125-.906 2-2 2H5c-1.125 0-2-.875-2-2v-6c0-1.094.875-2 2-2h7V8.5C12 6.031 14 4 16.5 4 18.969 4 21 6.031 21 8.5V10c0 .563-.469 1-1 1-.563 0-1-.438-1-1V8.5C19 7.125 17.875 6 16.5 6zM11 16c.531 0 1-.438 1-1 0-.531-.469-1-1-1H9c-.563 0-1 .469-1 1 0 .563.438 1 1 1h2z\" fill=\"#C00\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});