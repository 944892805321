define("ember-svg-jar/inlined/lock-door-closed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6 6c0-1.094.875-2 2-2h8c1.094 0 2 .906 2 2v12h2c.531 0 1 .469 1 1 0 .563-.469 1-1 1H4c-.563 0-1-.438-1-1 0-.531.438-1 1-1h2V6zm9 7c.531 0 1-.438 1-1 0-.531-.469-1-1-1-.563 0-1 .469-1 1 0 .563.438 1 1 1z\" fill=\"#00996F\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});