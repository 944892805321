define("ember-svg-jar/inlined/arrow-up-regular", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.101 229.899L23.9 249.698c4.686 4.686 12.284 4.686 16.971 0L196 94.568V436c0 6.627 5.373 12 12 12h28c6.627 0 12-5.373 12-12V94.568l155.13 155.13c4.686 4.686 12.284 4.686 16.971 0l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L230.485 3.515c-4.686-4.686-12.284-4.686-16.971 0L4.101 212.929c-4.687 4.686-4.687 12.284 0 16.97z\" fill=\"#00996e\"/>",
    "attrs": {
      "height": "448",
      "width": "444",
      "viewBox": "0 0 444 448",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});