define("ember-svg-jar/inlined/cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.5 19C4 19 2 17 2 14.5c0-1.938 1.25-3.625 3-4.219V10c0-2.75 2.219-5 5-5a4.967 4.967 0 014.313 2.531A2.853 2.853 0 0116 7a3 3 0 013 3c0 .406-.094.75-.219 1.094 1.844.375 3.219 2 3.219 3.906 0 2.219-1.813 4-4 4H6.5zm2.469-6.781c-.313.312-.313.781 0 1.062a.684.684 0 001.031 0l1.219-1.219v4.188c0 .438.344.75.75.75.437 0 .75-.313.75-.75v-4.188l1.219 1.22c.312.312.78.312 1.062 0 .313-.282.313-.75 0-1.063l-2.5-2.5c-.281-.281-.75-.281-1.063 0l-2.5 2.5h.032z\" fill=\"#00996F\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});