define("ember-svg-jar/inlined/lock-door-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13 5v15H4c-.563 0-1-.438-1-1 0-.531.438-1 1-1h1V6.906c0-.687.469-1.281 1.125-1.468L11.75 4.03c.281-.062.594 0 .844.188.25.187.406.5.406.781zm-2 7c0-.531-.344-1-.75-1-.438 0-.75.469-.75 1 0 .563.313 1 .75 1 .406 0 .75-.438.75-1zm3-4V6h3c1.094 0 2 .906 2 2v10h1c.531 0 1 .469 1 1 0 .563-.469 1-1 1h-2c-.563 0-1-.438-1-1V8h-3z\" fill=\"#C00\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});