define("ember-svg-jar/inlined/lockpad-battery-critical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1 6.5C1 5.133 2.094 4 3.5 4h8.75c1.367 0 2.5 1.133 2.5 2.5v11.25c0 1.406-1.133 2.5-2.5 2.5H3.5a2.468 2.468 0 01-2.5-2.5V6.5zm2.5.938v.625c0 .546.39.937.938.937h6.875c.507 0 .937-.39.937-.938v-.624a.95.95 0 00-.938-.938H4.439a.925.925 0 00-.938.938zm0 4.687v1.25c0 .352.273.625.625.625h2.5a.642.642 0 00.625-.625v-1.25c0-.313-.313-.625-.625-.625h-2.5a.642.642 0 00-.625.625zm.625 3.125a.642.642 0 00-.625.625v1.25c0 .352.273.625.625.625h2.5a.642.642 0 00.625-.625v-1.25c0-.313-.313-.625-.625-.625h-2.5zM8.5 12.125v1.25c0 .352.273.625.625.625h2.5a.642.642 0 00.625-.625v-1.25c0-.313-.313-.625-.625-.625h-2.5a.642.642 0 00-.625.625zm.625 3.125a.642.642 0 00-.625.625v1.25c0 .352.273.625.625.625h2.5a.642.642 0 00.625-.625v-1.25c0-.313-.313-.625-.625-.625h-2.5z\" fill=\"#2E2E2E\"/><path d=\"M23 7v2h-4c-.281 0-.5.25-.5.5v5c0 .281.219.5.5.5h3.688a2.707 2.707 0 00-.188 1c0 .375.063.719.188 1H19a2.468 2.468 0 01-2.5-2.5v-5C16.5 8.125 17.594 7 19 7h4zm8 10h-3.719c.125-.281.219-.625.219-1 0-.344-.094-.688-.219-1H31c.25 0 .5-.219.5-.5v-5c0-.25-.25-.5-.5-.5h-4V7h4c1.375 0 2.5 1.125 2.5 2.5v.5c.531 0 1 .469 1 1v2c0 .563-.469 1-1 1v.5c0 1.406-1.125 2.5-2.5 2.5zM25 7a.76.76 0 01.75.75v5.5A.74.74 0 0125 14a.722.722 0 01-.75-.75v-5.5A.74.74 0 0125 7zm-1 9c0-.531.438-1 1-1 .531 0 1 .469 1 1 0 .563-.469 1-1 1-.563 0-1-.438-1-1z\" fill=\"#C00\"/>",
    "attrs": {
      "width": "36",
      "height": "24",
      "viewBox": "0 0 36 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});