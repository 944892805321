define("ember-svg-jar/inlined/cloud-xmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.5 19C4 19 2 17 2 14.5c0-1.938 1.25-3.625 3-4.219V10c0-2.75 2.219-5 5-5a4.967 4.967 0 014.313 2.531A2.853 2.853 0 0116 7a3 3 0 013 3c0 .406-.094.75-.219 1.094 1.844.375 3.219 2 3.219 3.906 0 2.219-1.813 4-4 4H6.5zm2.969-8.531c-.313.312-.313.781 0 1.062L10.938 13l-1.47 1.469c-.312.312-.312.781 0 1.062a.684.684 0 001.032 0l1.469-1.469 1.469 1.47c.312.312.78.312 1.062 0 .313-.282.313-.75 0-1.063L13.031 13l1.469-1.469c.313-.281.313-.75 0-1.062-.281-.281-.75-.281-1.063 0l-1.468 1.469-1.469-1.47a.736.736 0 00-1.031 0z\" fill=\"#C00\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});